@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');  

body {
  margin: 0;
  font-family: 'Poppins';
}

.background {
    margin: 0;
    animation: gradient 15s ease infinite alternate;
    background-image: linear-gradient(45deg,#8b0d32 50%, #e21955 );
    background-size: 300% 300%;
    color: #fff;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden; 
}

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 250px;
  position: relative;
  margin-bottom: 5em;
}

.logo {
  min-width: 96%;
}

.slogan {
  font-size: 1em;
  letter-spacing: .1em;
}

.services {
  padding: 1em 0em 0em 0em;
}

.contact {
  padding: 2em 0em 0em 0em;
}

.contact-button {
  border: 1px solid #fff;
  background-color: #fff;
  color: #8b0d32;
  font-size: 1em;
  font-weight: bold;
  border: none;
  margin-bottom: 0em;
  border-radius: 5em;
  padding: .5em 2em;
  width: unset;
  text-decoration: none;
  width: 50%;
}

.contact-button:hover{
  border: 1px solid #e21955;
  color:  #e21955;
}

@media only screen and (min-width: 350px) {
  .box {
      width: 330px;
  }

  .slogan {
    font-size: 0.95em;
  }

  .services {
    font-size: 0.9em;
  }
}

@media only screen and (min-width: 768px) {
  .box {
      width: 420px;
  }

  .slogan {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 1200px) {
  .box {
      width: 530px;
  }

  .slogan {
    font-size: 1.5em;
  }

  .services {
    font-size: 1.05em;
  }

  .contact-button {
    font-size: 1.05em;
  }
}

@keyframes gradient {
    0% {
      background-position: 51% 0%;
    }
    50% {
      background-position: 50% 100%;
    }
    100% {
      background-position: 51% 0%;
    }
  }